<template>
  <div>
    <el-card v-loading="loadingFlag">
      <div>
        <el-button size="small" type="success" v-if="isShow" @click="isShow = false">编辑</el-button>
        <div v-else>
          <el-button type="primary" size="small" @click="submit">保存</el-button>
          <el-button @click="cancel" size="small">取消</el-button>
        </div>
      </div>
      <el-form ref="EditFormRef" :rules="rule" :model="editForm" label-width="120px" class="FormCl" :disabled="isShow">
        <el-form-item label="RFID：" size="small" prop="rfid_no">
          <el-input ref="cardRef" v-model="editForm.rfid_no" :disabled="editForm.rfid_no.length == 10">
            <template slot="append">
              <el-link type="primary" class="vg_pointer" @click="emptyChange" disabled>清空</el-link>
            </template></el-input
          >
        </el-form-item>
        <el-form-item label="样品编号：" size="small" prop="smpl_no">
          <el-input v-model="editForm.smpl_no" :disabled="true" placeholder="请选择样品编号">
            <template slot="append">
              <el-link type="primary" @click="openSampDialog()" disabled class="vg_pointer">选择</el-link>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="委托单号：" size="small" prop="requ_no">
          <el-input v-model="editForm.requ_no" :disabled="true" placeholder="请选择委托单号"> </el-input>
        </el-form-item>
        <el-form-item label="所属部门：" size="small" required>
          <el-input v-model="editForm.belo_dept_name" disabled placeholder="请选择所属部门"> </el-input>
        </el-form-item>

        <el-form-item label="开卡人姓名:" size="small" prop="stff_name">
          <el-input :disabled="true" v-model="editForm.stff_name"></el-input>
        </el-form-item>
        <el-form-item label="是否需要经历：">
          <el-checkbox v-model="editForm.open_status" :disabled="open_disabled >= 2">开版</el-checkbox>
          <el-checkbox v-model="editForm.stpr_status" :disabled="stpr_disabled >= 2">备料</el-checkbox>
          <el-checkbox v-model="editForm.comp_status" :disabled="comp_disabled >= 2">复合</el-checkbox>
          <el-checkbox v-model="editForm.stam_status" :disabled="stam_disabled >= 2">印花</el-checkbox>
          <el-checkbox v-model="editForm.embr_status" :disabled="embr_disabled >= 2">电绣</el-checkbox>
          <el-checkbox v-model="editForm.mase_status" :disabled="mase_disabled >= 2">机缝</el-checkbox>
          <el-checkbox v-model="editForm.lase_status" :disabled="lase_disabled >= 2">激光</el-checkbox>
          <el-checkbox v-model="editForm.manu_status" :disabled="manu_disabled >= 2">手工</el-checkbox>
          <el-checkbox v-model="editForm.emcd_status" :disabled="emcd_disabled >= 2">制卡</el-checkbox>

          <el-checkbox v-model="editForm.insp_status" :disabled="true">检验</el-checkbox>
        </el-form-item>
      </el-form>

      <!-- 样品编号 -->
      <el-dialog :visible.sync="dialogSampVisible" width="65%" title="样品信息列表(未开卡)">
        <template>
          <el-form ref="form" :model="searchSampForm" label-width="100px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="样品编号：">
                  <el-input v-model="searchSampForm.smpl_no" size="small" placeholder="请输入样品编号" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="样品名称：">
                  <el-input v-model="searchSampForm.smpl_name" size="small" placeholder="请输入样品名称" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item label="所属部门:">
                  <selectSectionType @selectStatusRow="getStatusVal2" :disabled="selectRefuDisabled" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="vg_mb_16">
              <el-col :md="8">
                <el-button class="vg_ml_16" type="primary" size="small" @click="searchSamp">搜索</el-button>
                <el-button class="vg_mr_8" type="info" size="small" @click="searchSampO">刷新</el-button>
                <!-- </el-col>
              <el-col :md='5'> -->
                <el-link type="primary" @click="gosmpl" class="vg_cursor"><i class="el-icon-edit-outline"></i>样品总控表</el-link>
              </el-col>
            </el-row>
            <div class="vg_mb_8"><i class="el-icon-info vg_mr_8"></i>{{ title }}</div>
            <el-table :data="SampList" border @cell-dblclick="sampDbClick" style="width: 100%" v-loading="loadFlag" class="vg_pointer">
              <el-table-column type="index" label="序号" align="center"></el-table-column>
              <el-table-column prop="smpl_no" label="样品编号"> </el-table-column>
              <el-table-column prop="smpl_cust_no" label="客户货号">
                <template slot-scope="scope">
                  <span v-if="scope.row.smpl_cust_no">{{ scope.row.smpl_cust_no }}</span>
                  <span v-else class="vg_9f9a9a">暂无</span>
                </template>
              </el-table-column>
              <el-table-column prop="requ_no" label="委托编号"></el-table-column>
              <el-table-column prop="smpl_name" label="样品名称"> </el-table-column>
              <el-table-column prop="smpl_type" label="样品类型" :formatter="formatSmplType"> </el-table-column>
              <el-table-column prop="smpl_num" label="样品数量">
                <template slot-scope="scope">
                  <span v-if="scope.row.smpl_num || scope.row.smpl_num === 0">{{ scope.row.smpl_num | formatSmplNum }}</span>
                  <span v-else class="vg_9f9a9a">暂无</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="smpl_deil_time"
                label="实际交样时间"
                :formatter="formatDate"
              >
              </el-table-column> -->
              <el-table-column prop="smpl_price" label="样品间报价">
                <template slot-scope="scope">
                  <span v-if="scope.row.smpl_price">{{ scope.row.smpl_price | foamatSmplPrice }}</span>
                  <span v-else class="vg_9f9a9a">暂无</span>
                </template>
              </el-table-column>
              <el-table-column prop="smpl_verify_price" label="采购部核价">
                <template slot-scope="scope">
                  <span v-if="scope.row.smpl_verify_price">{{ scope.row.smpl_verify_price | formatSmplVerifyPrice }}</span>
                  <span v-else class="vg_9f9a9a">暂无</span>
                </template>
              </el-table-column>
              <el-table-column prop="belo_dept_name" label="所属部门"></el-table-column>
            </el-table>
            <pubPagination :totalPage="SampTotal" @changePageSearch="changeSampPageSearch" ref="pubPagination"></pubPagination>
          </el-form>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { smplAPI } from '@api/modules/smpl'; //getSmpls
import { rfidAPI } from '@api/modules/rfid';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import helper from '@assets/js/helper.js';
export default {
  name: 'procEdit',
  data() {
    return {
      editForm: {
        rfid_no: '',
        smpl_no: null,
        requ_no: null,
        belo_dept_name: null,
        belo_dept_id: null
      },
      rule: {
        rfid_no: [{ required: true, trigger: 'blur', message: ' ' }],
        smpl_no: [{ required: true, trigger: 'blur', message: ' ' }],
        requ_no: [{ required: true, trigger: 'blur', message: ' ' }],
        stff_id: [{ required: true, trigger: 'blur', message: ' ' }],
        stff_name: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      dialogSampVisible: false,
      SampList: [], //样品
      SampTotal: 0,
      searchSampForm: {
        page_no: 1,
        smpl_no: null,
        smpl_name: null
      },
      smpl_id: '',
      requ_id: '',
      isShow: true,
      title: ' 提示：(双击表编对应数据即可选中人员)',
      loadingFlag: true,
      loadFlag: true,
      stpr_disabled: 0,
      comp_disabled: 0,
      lase_disabled: 0,
      embr_disabled: 0,
      mase_disabled: 0,
      manu_disabled: 0,
      insp_disabled: 0,
      stam_disabled: 0,
      open_disabled: 0,
      emcd_disabled: 0,
      selectRefuDisabled: false
    };
  },
  filters: {
    foamatSmplPrice(row) {
      return helper.haveFour(row);
    },
    formatSmplVerifyPrice(row) {
      return helper.haveFour(row);
    },
    formatSmplNum(row) {
      return helper.haveFour(row);
    }
  },
  methods: {
    //获取打样部门
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if (userInfo.isDYJ) {
        this.editForm.dept_id = userInfo.dept_id;
        this.selectRefuDisabled = true;
        this.getSampList();
      } else {
        this.getSampList();
      }
    },
    // 获取 卡数据
    getEditForm() {
      get(rfidAPI.get_rfid_by_id, { rfid_id: this.$route.query.rfid_id })
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }

          this.editForm = res.data.data;
          this.smpl_id = res.data.data.smpl_id;
          this.requ_id = res.data.data.requ_id;
          this.stpr_disabled = res.data.data.stpr_status;
          this.editForm.stpr_status = res.data.data.stpr_status != 0 ? true : false;
          this.comp_disabled = res.data.data.comp_status;
          this.editForm.comp_status = res.data.data.comp_status != 0 ? true : false;
          this.lase_disabled = res.data.data.lase_status;
          this.editForm.lase_status = res.data.data.lase_status != 0 ? true : false;
          this.embr_disabled = res.data.data.embr_status;
          this.editForm.embr_status = res.data.data.embr_status != 0 ? true : false;
          this.mase_disabled = res.data.data.mase_status;
          this.editForm.mase_status = res.data.data.mase_status != 0 ? true : false;
          this.manu_disabled = res.data.data.manu_status;
          this.editForm.manu_status = res.data.data.manu_status != 0 ? true : false;
          this.insp_disabled = res.data.data.insp_status;
          this.editForm.insp_status = res.data.data.insp_status != 0 ? true : false;
          this.stam_disabled = res.data.data.stam_status;
          this.editForm.stam_status = res.data.data.stam_status != 0 ? true : false;
          this.open_disabled = res.data.data.open_status;
          this.editForm.open_status = res.data.data.open_status != 0 ? true : false;
          this.emcd_disabled = res.data.data.emcd_status;
          this.editForm.emcd_status = res.data.data.emcd_status != 0 ? true : false;

          //部门信息
          this.editForm.belo_dept_name = res.data.data.belo_dept_name;
          this.editForm.belo_dept_id = res.data.data.belo_dept_id;
          setTimeout(() => {
            this.loadingFlag = false;
            this.isShowInputer = true;
          }, 500);
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 样品类型判断
    formatSmplType(row) {
      if (row.smpl_type === 1) {
        return '新款';
      } else if (row.smpl_type === 2) {
        return '老款';
      } else if (row.smpl_type === 3) {
        return '修改款';
      }
    },
    //获取样品数据
    getSampList() {
      get(smplAPI.get_all_smpls_v1, this.searchSampForm)
        .then(res => {
          if (res.data.code !== 0) {
            return console.log('数据请求失败');
          }
          this.SampList = res.data.data.list;
          this.SampTotal = res.data.data.total;
          setTimeout(() => {
            this.loadFlag = false;
          }, 300);
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    openSampDialog() {
      this.dialogSampVisible = true;
    },
    //样品 双击获取数据
    sampDbClick(val) {
      this.smpl_id = val.smpl_id;
      this.editForm.smpl_no = val.smpl_no;
      this.editForm.requ_no = val.requ_no;
      this.requ_id = val.requ_id;
      this.editForm.belo_dept_id = val.belo_dept_id;
      this.editForm.belo_dept_name = val.belo_dept_name;
      this.dialogSampVisible = false;
    },
    changeSampPageSearch(val) {
      this.searchSampForm.page_no = val;
      this.getSampList();
    },
    searchSamp() {
      this.searchSampForm.page_no = 1;
      this.loadFlag = true;
      this.getSampList();
    },
    // 刷新
    searchSampO() {
      this.searchSampForm.page_no = 1;
      this.searchSampForm.smpl_no = null;
      this.searchSampForm.smpl_name = null;
      this.loadFlag = true;
      this.getSampList();
    },
    submit() {
      this.$confirm('是否修改卡?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const editForm = {};
          editForm.smpl_id = this.smpl_id;
          editForm.requ_id = this.requ_id;
          editForm.rfid_no = this.editForm.rfid_no;
          editForm.rfid_id = this.editForm.rfid_id;

          editForm.open_status = this.open_disabled >= 2 ? this.open_disabled : this.editForm.open_status - 0;
          editForm.stpr_status = this.stpr_disabled >= 2 ? this.stpr_disabled : this.editForm.stpr_status ? 1 : 0;
          editForm.comp_status = this.comp_disabled >= 2 ? this.comp_disabled : this.editForm.comp_status ? 1 : 0;
          editForm.stam_status = this.stam_disabled >= 2 ? this.stam_disabled : this.editForm.stam_status ? 1 : 0;
          editForm.embr_status = this.embr_disabled >= 2 ? this.embr_disabled : this.editForm.embr_status ? 1 : 0;
          editForm.mase_status = this.mase_disabled >= 2 ? this.mase_disabled : this.editForm.mase_status ? 1 : 0;
          editForm.lase_status = this.lase_disabled >= 2 ? this.lase_disabled : this.editForm.lase_status ? 1 : 0;
          editForm.manu_status = this.manu_disabled >= 2 ? this.manu_disabled : this.editForm.manu_status ? 1 : 0;
          editForm.emcd_status = this.emcd_disabled >= 2 ? this.emcd_disabled : this.editForm.emcd_status ? 1 : 0;
          editForm.insp_status = this.insp_disabled >= 2 ? this.insp_disabled : this.editForm.insp_status ? 1 : 0;
          post(rfidAPI.edit_rfid, editForm)
            .then(res => {
              if (res.data.code !== 0) {
                return this.$message.error(res.data.msg);
              }
              this.$message.success(res.data.msg);
              this.isShow = true;
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {});
    },
    cancel() {
      this.$confirm('是否取消修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.jump('open_list');
        })
        .catch(() => {});
    },
    emptyChange() {
      this.editForm.rfid_no = '';
      this.$nextTick(() => {
        this.$refs.cardRef.focus();
      });
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toTimeDay(row.smpl_deil_time);
    },
    //跳转样品总控表
    gosmpl() {
      this.jump(`smpl_list?perm_id=${this.helper.retPermId('smpl')}`);
    },
    //选择所属部门
    getStatusVal2(val) {
      this.searchSampForm.belo_dept_id = val;
      this.getSampList();
    }
  },
  created() {
    this.getEditForm();
    // this.getSampList();
    this.getDepartment();
  },
  components: {
    pubPagination,
    selectSectionType
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 10px;
}
.vd_tips {
  font-size: 12px;
  color: $color-info;
}
</style>
